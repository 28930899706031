import { FetchLayerDataResult } from '@deck.gl/carto/typed/api/maps-v3-client'
import { Metadata, Asset, Environment } from '@workspaces/types'
import { AssetsService, CacheManager } from '@workspaces/services'
import {
  getCacheInforForModel,
  getChunkSizeForDownloadingAssets,
  isMultiCountry,
} from '@/plan/metadata/metadata.helper'
import { getFromIndexDb, getKeyValue } from '@/cache/indexDb.helper'
import { Filter } from '@/plan/filter/filter.type'
import { getCountriesForCurrentPlan } from '@/helpers/plan.helper'
import { getInstance as getAuth0ServiceIntance } from '@/auth/index'
import { downloadAssetsPropertiesInBackground } from '@/helpers/downloader.helper'
import SharedPlanPasswordManager from '@/helpers/sharedPlanPassword.helper'

// function prepareFetchLayerQueries(
//   meta: Metadata.AppMetadata,
//   environment: Environment.EnvironmentResolver,
//   assetsCountByCountries: Asset.AssetsCountPerCountry[],
// ): string[] {
//   const maxAssetsPerQuery = 50000
//   const countryIds = getCountriesIdsFromMetadata(meta)
//   let queriesParams: {
//     countryId: number | undefined
//     limit: number
//     offset: number
//   }[] = []
//   assetsCountByCountries.forEach((assetsCountPercountry) => {
//     const { country_id: countryId, assets } = assetsCountPercountry
//     // If country obtained from DDBB is selected in the metadata
//     if (countryIds.includes(countryId)) {
//       const queriesNumber = Math.ceil(assets / maxAssetsPerQuery)
//       for (let i = 0; i < queriesNumber; i++) {
//         const limit = maxAssetsPerQuery
//         const offset = i * maxAssetsPerQuery
//         queriesParams.push({ countryId, limit, offset })
//       }
//     }
//   })

//   if (!isMultiCountry(meta)) {
//     queriesParams = queriesParams.map((query) => ({
//       ...query,
//       countryId: undefined,
//     }))
//   }

//   const queries: string[] = queriesParams.map(({ countryId, limit, offset }) =>
//     AssetsService.fetchLayerSource(meta, environment, countryId, limit, offset),
//   )

//   return queries
// }

async function getDataFromIndexDb(
  meta: Metadata.AppMetadata,
): Promise<FetchLayerDataResult | null> {
  const {
    dbName,
    store,
    key,
    key_last_update: keyLastUpdate,
    version,
  } = getCacheInforForModel(meta.data_model.assets)
  const assetsLastUpdateInCache = await getFromIndexDb(
    dbName,
    version,
    store,
    keyLastUpdate,
  )
  const cacheManagerAssetsLastUpdate =
    CacheManager.getInstance().getCacheInfoForTable(
      meta.data_model.assets.table_name,
    )
  console.debug(
    `📌 Assets last update in cache ${assetsLastUpdateInCache} and in cacheManager ${cacheManagerAssetsLastUpdate}`,
  )
  if (assetsLastUpdateInCache === cacheManagerAssetsLastUpdate) {
    console.debug(
      '📌 Assets content in cache is up-to-date. Getting data from cache',
    )
    const assets = await getFromIndexDb(dbName, version, store, key)
    if (assets !== null && Array.isArray(assets) && assets.length > 0) {
      console.debug('📌 Assets properties found in indexDb')
      const dataFormatted = {
        schema: [],
        data: { features: assets },
      }
      return dataFormatted
    }
  }
  return null
}

export default {
  async getCountriesUsedInPlan(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    filters: Filter[] | null,
  ): Promise<Asset.AssetsCountPerCountry[]> {
    // Get the countries that are being used in the plan. If the plan is new, we have to use the user's preseted countries
    let countriesToDownload = filters ? getCountriesForCurrentPlan(filters) : []
    if (countriesToDownload.length === 0) {
      // TODO: Qué pasa si eres no eres multi country?
      countriesToDownload = getAuth0ServiceIntance().getUserCountries()
    }

    const assetsCountByCountries: Asset.AssetsCountPerCountry[] =
      await AssetsService.getAssetsCountPerCountry(meta, environment)

    let assetsCountByCountriesToDownload: Asset.AssetsCountPerCountry[] =
      assetsCountByCountries
    // Download only the countries that are being used in the plan, intersecting with the countries that have assets
    if (isMultiCountry(meta)) {
      assetsCountByCountriesToDownload = assetsCountByCountries.filter(
        (assetCount) => countriesToDownload.includes(assetCount.country_id),
      )
    } else {
      assetsCountByCountriesToDownload[0].country_id = -1
    }

    return assetsCountByCountriesToDownload
  },
  async getCountriesToBeFetchedAfterEvaluatingCache(
    meta: Metadata.AppMetadata,
    assetsCountByCountry: Asset.AssetsCountPerCountry[],
  ): Promise<Asset.AssetsCountPerCountry[]> {
    const lastUpdateTimestampAssetsTable =
      CacheManager.getInstance().getCacheInfoForTable(
        meta.data_model.assets.table_name,
      )
    const {
      dbName,
      store,
      key_last_update: keyLastUpdate,
      version,
    } = getCacheInforForModel(meta.data_model.assets)

    const assetsCountByCountryToBeFetched: Asset.AssetsCountPerCountry[] = []
    for (const assetCount of assetsCountByCountry) {
      const assetsLastUpdateInCache = await getFromIndexDb(
        dbName,
        version,
        store,
        getKeyValue(assetCount.country_id, keyLastUpdate),
      )
      console.debug(
        `🐨 Country ${assetCount.country_id} last update in cache is ${assetsLastUpdateInCache} vs current value ${lastUpdateTimestampAssetsTable}`,
      )
      if (assetsLastUpdateInCache !== lastUpdateTimestampAssetsTable) {
        console.debug(
          '     🐨 Adding it to the list of countries to be fetched',
        )
        assetsCountByCountryToBeFetched.push(assetCount)
      } else {
        console.debug('     🐨 Country is up-to-date, no need to be fetched')
      }
    }

    return assetsCountByCountryToBeFetched
  },
  async getAssetsByCountryFromCache(
    meta: Metadata.AppMetadata,
    assetsCountByCountries: Asset.AssetsCountPerCountry[],
  ): Promise<Asset.GeoJSONAsset[]> {
    console.debug('🐨    Fetching assets from cache')
    const { dbName, store, key, version } = getCacheInforForModel(
      meta.data_model.assets,
    )
    let assetsByCountry: Asset.GeoJSONAsset[] = []
    for (const assetCount of assetsCountByCountries) {
      console.debug(
        `        🐨 Fetching assets for country ${assetCount.country_id} from cache`,
      )
      const assets = await getFromIndexDb(
        dbName,
        version,
        store,
        getKeyValue(assetCount.country_id, key),
      )
      if (assets !== null && Array.isArray(assets) && assets.length > 0) {
        assetsByCountry = assetsByCountry.concat(assets)
      }
      console.debug('            🐨 Fetched')
    }
    console.debug('🐨    Assets fetched from cache')
    return assetsByCountry
  },
  async fetchLayerV2(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    filters: Filter[] | null,
  ): Promise<{
    allGeoJsonAssets: FetchLayerDataResult
    needToGetSecondaryProperties: boolean
  }> {
    const assetsCountByCountriesFromPlan = await this.getCountriesUsedInPlan(
      meta,
      environment,
      filters,
    )
    const assetsCountByCountriesToFetch =
      await this.getCountriesToBeFetchedAfterEvaluatingCache(
        meta,
        assetsCountByCountriesFromPlan,
      )

    const assetsCountByCountriesInCache = assetsCountByCountriesFromPlan.filter(
      (country) => {
        return !assetsCountByCountriesToFetch.some(
          (countryToFetch) => countryToFetch.country_id === country.country_id,
        )
      },
    )
    console.debug('🐨     Assets in cache', assetsCountByCountriesInCache)
    console.debug('🐨     Assets to be fetched', assetsCountByCountriesToFetch)

    let geoJsonAssets: Asset.GeoJSONAsset[] =
      await this.getAssetsByCountryFromCache(
        meta,
        assetsCountByCountriesInCache,
      )

    console.debug(
      '🐨     Fetching assets from cloud',
      assetsCountByCountriesToFetch,
    )
    const chunkSize = getChunkSizeForDownloadingAssets(meta)
    const geoJsonAssetsByCountry: Asset.AssetsGeoJsonFormattedByCountry[] =
      await AssetsService.getAllAssetsWithStartupProperties(
        meta,
        environment,
        assetsCountByCountriesToFetch,
        chunkSize,
      )
    console.debug('      🐨 Assets fetched', assetsCountByCountriesToFetch)

    for (const assetsByCountry of geoJsonAssetsByCountry) {
      geoJsonAssets = geoJsonAssets.concat(assetsByCountry.assets)
    }

    const dataFormatted = {
      schema: [],
      data: { features: geoJsonAssets },
    }

    if (assetsCountByCountriesToFetch.length > 0) {
      console.debug(
        '🐨     Giving the order to download secondary properties for the follwing assets',
        assetsCountByCountriesToFetch,
      )
      downloadAssetsPropertiesInBackground(
        assetsCountByCountriesToFetch,
        chunkSize,
        geoJsonAssetsByCountry,
      )
    }

    console.debug('🐨     Assets v2 fetched')
    return {
      allGeoJsonAssets: dataFormatted,
      needToGetSecondaryProperties: assetsCountByCountriesToFetch.length > 0,
    }
  },

  async getAllMediaTypeOptionsForSharedPlan(
    meta: Metadata.AppMetadata,
    planId: string,
    activeSubfilterIndex: number,
    environment: Environment.EnvironmentResolver,
  ): Promise<{ allDigital: boolean; allStatic: boolean }> {
    const password = SharedPlanPasswordManager.getInstance().getPassword()
    const data = await AssetsService.getAllMediaTypeOptionsForSharedPlan(
      meta,
      planId,
      activeSubfilterIndex,
      environment,
      password,
    )
    return data
  },
}
